import React from 'react';
import Contact from '../components/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
  return (
    <>
      <Contact />
    </>
  );
};
